import { ApiRef, ProfileInfoApi, BackstageIdentityApi, SessionApi, createApiRef } from "@backstage/core-plugin-api";

const samlAuthApiRef: ApiRef<
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi
> = createApiRef({
    id: 'internal.auth.saml',
});

export default samlAuthApiRef;